<template>
  <div class="content">
    <div class="sidebar">
      <button class="btn btn-primary" id="icon" data-bs-toggle="tooltip" data-bs-placement="right" title="Mérések">
        <i class="fa-solid fa-chart-column"></i>
      </button>
      <button class="btn btn-primary" id="icon" data-bs-toggle="tooltip" data-bs-placement="right" title="Profil">
        <i class="fa-solid fa-user"></i>
      </button>
      <button class="btn btn-primary" id="icon" data-bs-toggle="tooltip" data-bs-placement="right" title="Felhasználók" @click="toUsers()">
        <i class="fa-solid fa-users"></i>
      </button>
      <button class="btn btn-primary" id="icon" data-bs-toggle="tooltip" data-bs-placement="right" title="Bejegyzések" @click="toLogs()">
        <i class="fa-solid fa-list"></i>
      </button>
      <button class="btn btn-primary" id="iconActive" data-bs-toggle="tooltip" data-bs-placement="right" title="Bázisállomások" @click="toStations()">
        <i class="fa-solid fa-tower-cell"></i>
      </button>
      <button class="btn btn-primary" id="icon" data-bs-toggle="tooltip" data-bs-placement="right" title="Kijelentkezés" @click="this.logout()">
        <i class="fa-solid fa-arrow-right-from-bracket"></i>
      </button>
    </div>

    <div class="stationMap">
      <div class="map" id="new-station-map"></div>
    </div>
    <div class="station-data">
      <table class="table" id="station-table">
        <thead>
          <tr>
            <th scope="col">DSS-34</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Típus 10</td>
          </tr>
          <tr>
            <td>Győr, Egyetem tér 1</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="viewModel" ref="modelContainer"></div>
  </div>
</template>

<script>
import MapboxGl from "mapbox-gl"; // or "const mapboxgl = require('mapbox-gl');"

import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export default {
  data() {
    return {
      mymap: undefined,

      map: undefined,
    };
  },
  name: "StationPage",
  props: {
    msg: String,
  },

  methods: {
    logout(){
        this.$router.push("/");
    },  
    toUsers() {
      this.$router.push("../userList");
    },
    toMeasures() {
      this.$router.push("../listView");
    },
    toLogs() {
      this.$router.push("../logList");
    },
    toStations() {
      this.$router.push("../stationList");
    },
    initThree() {
      // Create a scene
      this.scene = new THREE.Scene();

      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load("blur.jpg");

      this.scene.background = texture;
      // Create a camera
      this.camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      this.camera.position.z = 5;

      this.camera.near = 0.1; // Adjust according to your scene
      this.camera.far = 1000; // Adjust according to your scene
      this.camera.updateProjectionMatrix();

      // Create a renderer
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setSize(window.innerWidth, window.innerHeight);

      this.renderer.autoClear = true;
      this.renderer.autoClearColor = true

      // Append the renderer to the <div id="model-container"></div>
      this.$refs.modelContainer.appendChild(this.renderer.domElement);

      // Add a resize listener to update the renderer size on window resize
      window.addEventListener("resize", () => {
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
      });
    },
    loadGLTFModel() {
      const loader = new GLTFLoader();

      // Load the GLTF model
      loader.load(
        "tower.gltf",
        (gltf) => {
          // Add the loaded model to the scene
          this.scene.add(gltf.scene);

          // Animate the model if needed
          this.animate();
        },
        (xhr) => {
          // Progress callback
          console.log(`${(xhr.loaded / xhr.total) * 100}% loaded`);
        },
        (error) => {
          console.error("An error occurred while loading the model", error);
        }
      );

      const ambientLight = new THREE.AmbientLight(0xffffff, 1); // Color, Intensity
      this.scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5); // Color, Intensity
      directionalLight.position.set(1, 1, 1); // Set light position
      this.scene.add(directionalLight);

      const pointLight = new THREE.PointLight(0xffffff, 0.5); // Color, Intensity
      pointLight.position.set(0, 3, 0); // Set light position
      this.scene.add(pointLight);

      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = true; // Enable smooth camera movements
      this.controls.dampingFactor = 0.05; // Adjust damping factor for desired responsiveness
      this.controls.rotateSpeed = 0.5; // Adjust rotation speed
    },
    animate() {
      // Render the scene with the camera
      this.renderer.render(this.scene, this.camera);

      this.controls.update(); // Update controls in each frame

      // Call animate recursively to create a smooth animation loop
      requestAnimationFrame(this.animate);
    },
  },
  mounted() {
    this.map = new MapboxGl.Map({
      container: "new-station-map",
      style: "mapbox://styles/mapbox/light-v11",
      projection: "globe",
      accessToken:
        "pk.eyJ1IjoiY3NhYmluZW1ldGgiLCJhIjoiY2xlOGxibzV3MGFvdDN2cXNjbnM2Zm83dSJ9.1TJNwxyuw5o0pPxHZlfROQ",
      zoom: 3,
      center: [17.270323, 47.89035],
      antialias: true,
      pitch: 45,
      bearing: -18,
    });

    const nav = new MapboxGl.NavigationControl();
    this.map.addControl(nav, "bottom-left");

    this.map.on("style.load", () => {
      // Insert the layer beneath any symbol layer.
      const layers = this.map.getStyle().layers;
      const labelLayerId = layers.find(
        (layer) => layer.type === "symbol" && layer.layout["text-field"]
      ).id;

      // The 'building' layer in the Mapbox Streets
      // vector tileset contains building height data
      // from OpenStreetMap.
      this.map.addLayer(
        {
          id: "add-3d-buildings",
          source: "composite",
          "source-layer": "building",
          filter: ["==", "extrude", "true"],
          type: "fill-extrusion",
          minzoom: 15,
          paint: {
            "fill-extrusion-color": "#aaa",

            // Use an 'interpolate' expression to
            // add a smooth transition effect to
            // the buildings as the user zooms in.
            "fill-extrusion-height": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "height"],
            ],
            "fill-extrusion-base": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "min_height"],
            ],
            "fill-extrusion-opacity": 0.6,
          },
        },
        labelLayerId
      );
      //document.getElementById("timeRange").max = this.allTime.

      // document.getElementById("customRange").max = this.allTime.count();
    });

    new MapboxGl.Marker({
      color: "green", // Replace with your desired marker color
      symbol: "1",
    })
      .setLngLat([17, 47])
      .addTo(this.map);

    this.initThree();
    this.loadGLTFModel();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  background: linear-gradient(
    180deg,
    #1d2138 2.92%,
    #222954 46.15%,
    #222954 67.5%
  );
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.sidebar {
  position: absolute;
  left: 0;
  height: 100vh;
  top: 0;
  width: 4vw;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 20px 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.small-img {
  background-image: url("../../public/smaller/torony3.jpg");
  width: 150px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 20%;
  position: relative;
  margin: 10px;
}

.small-img2 {
  background-image: url("../../public/smaller/torony4.jpg");
  width: 150px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 20%;
  position: relative;
  margin: 10px;
}

.smalls {
  display: flex;
}

#iconActive {
  width: auto;
  height: auto;
  font-size: 20px;
  background-color: transparent;
  border: 0;
  margin-bottom: 10px;
}

#icon {
  width: auto;
  height: auto;
  font-size: 20px;
  background-color: transparent;
  border: 0;
  opacity: 50%;
  margin-bottom: 10px;
}

#icon:hover {
  opacity: 100%;
}

.map {
  width: 94vw;
  height: 98vh;
  top: 1vh;
  border: 1px solid white;
  position: absolute;
  left: 5vw;
  border-radius: 20px;
}

.viewModel {
  position: absolute;
  width: 30vw;
  height: 90vh;
  background-color: #222954;
  right: 2vw;
  top: 5vh;
  border-radius: 20px;
  overflow: hidden;
}

.gallery {
  width: 29vw;
  border-radius: 20px;
  background-color: #1d2138;
  height: 98vh;
  top: 1vh;
  position: absolute;
  right: 1vw;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#carousel1 {
  width: auto;
  height: auto;
  max-height: 80vh;
  border-radius: 20px;
}

#picture {
  width: 29vw;
  z-index: 100;
  border-radius: 20px;
  max-height: 80vh;
}

.station-data {
  position: absolute;
  background-color: #222954;
  width: 11vw;
  left: 6vw;
  top: 3vh;
  height: 20vh;
  z-index: 11110;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#station-table {
  background-color: transparent;
  color: white;
}
</style>
