<template>
  <div class="content">
    <breeding-rhombus-spinner
      :animation-duration="1500"
      :size="100"
      color="#ffff"
      id="spinner"
    />
    <div class="sidebar">
      <button class="btn btn-primary" id="iconActive" data-bs-toggle="tooltip" data-bs-placement="right" title="Mérések">
        <i class="fa-solid fa-chart-column"></i>
      </button>
      <button class="btn btn-primary" id="icon" data-bs-toggle="tooltip" data-bs-placement="right" title="Profil">
        <i class="fa-solid fa-user"></i>
      </button>
      <button class="btn btn-primary" id="icon" data-bs-toggle="tooltip" data-bs-placement="right" title="Felhasználók" @click="toUsers()">
        <i class="fa-solid fa-users"></i>
      </button>
      <button class="btn btn-primary" id="icon" data-bs-toggle="tooltip" data-bs-placement="right" title="Bejegyzések" @click="toLogs()">
        <i class="fa-solid fa-list"></i>
      </button>
      <button class="btn btn-primary" id="icon" data-bs-toggle="tooltip" data-bs-placement="right" title="Bázisállomások" @click="toStations()">
        <i class="fa-solid fa-tower-cell"></i>
      </button>
      <button class="btn btn-primary" id="icon" data-bs-toggle="tooltip" data-bs-placement="right" title="Kijelentkezés" @click="this.logout()">
        <i class="fa-solid fa-arrow-right-from-bracket"></i>
      </button>
    </div>
    <div id="blur-div"></div>
    <div class="main-content">
      <div class="main-data-title">
        <table class="table-bordered" id="title-table">
          <thead>
            <tr>
              <th scope="col">Név</th>
              <th scope="col">Helyszín</th>
              <th scope="col">Típus</th>
              <th scope="col">Eszköz</th>
              <th scope="col">Időtartam</th>
              <th scope="col">Mérnök</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="col">Mérés 1</th>
              <th scope="col">Győr, Egyetem tér 1</th>
              <th scope="col">Típus 1</th>
              <th scope="col">Eszköz 10</th>
              <th scope="col">
                {{ this.measureStartTime }} - {{ this.measureEndTime }}
              </th>
              <th scope="col">Kovács Tamás</th>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row1">
        <div class="main-table">
          <table class="table-bordered" id="data-table">
            <thead>
              <tr>
                <th scope="col">Adattípus</th>
                <th scope="col">Érték</th>
                <th scope="col">Mértékegység</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="this.isRSRP">
                <td>RSRP</td>
                <td>{{ this.actualRSRP }}</td>
                <td>dBm</td>
              </tr>
              <tr v-if="this.isRSRQ">
                <td>RSRQ</td>
                <td>{{ this.actualRSRQ }}</td>
                <td>dB</td>
              </tr>
              <tr v-if="this.isRSSI">
                <td>RSSI</td>
                <td>{{ this.actualRSSI }}</td>
                <td>dBm</td>
              </tr>
              <tr v-if="this.isDL">
                <td>DL</td>
                <td>{{ this.actualDL }}</td>
                <td>Mbps</td>
              </tr>
              <tr v-if="this.isUL">
                <td>UL</td>
                <td>{{ this.actualUL }}</td>
                <td>Mbps</td>
              </tr>
              <tr v-if="this.isEARFCN">
                <td>EARFCN</td>
                <td>{{ this.actualEARFCN }}</td>
                <td></td>
              </tr> 
              <tr v-if="this.isPUSCH">
                <td>PUSCH Thrpt</td>
                <td>{{ this.actualPUSCH }} Mbps</td>
                <td>Mbps</td>
              </tr>
              <tr v-if="this.isPCI">
                <td>PCI</td>
                <td>{{ this.actualPCI }}</td>
                <td></td>
              </tr>
              <tr>
                <td>GPS</td>
                <td>{{ this.actualLat }}, {{ this.actualLong }}</td>
                <td>°</td>
              </tr>
              <tr>
                <td>ALT</td>
                <td>{{ this.actualAlt }}</td>
                <td>m</td>
              </tr>
              <tr v-if="this.isGPS">
                <td>GPS status</td>
                <td>{{ this.actualGPS }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <button
            class="btn btn-primary"
            id="export-button"
            @click="exportToCSV()"
          >
            <i class="fa-solid fa-file-csv"></i> Export
          </button>
        </div>

        <div class="main-charts">
          <div class="chart-btns">
            <button class="btn btn-primary" @click="toLineChart" id="chart-btn">
              <i class="fa-solid fa-chart-line"></i></button
            ><br /><button
              class="btn btn-primary"
              @click="toRadarChart"
              id="chart-btn"
            >
              <i class="fa-solid fa-chart-pie"></i>
            </button>
          </div>
          <canvas id="measurements"></canvas>
          <canvas id="measurements2"></canvas>
        </div>
      </div>

      <div class="row2">
        <div class="dataPicker">
          <div class="dropdown">
            <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownData"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Lekért adatok száma: {{ this.dataCount }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownData">
              <li>
                <a class="dropdown-item" href="#" @click="changeData100()"
                  >100</a
                >
              </li>
              <li>
                <a class="dropdown-item" href="#" @click="changeData500()"
                  >500</a
                >
              </li>
              <li>
                <a class="dropdown-item" href="#" @click="changeData1000()"
                  >1000</a
                >
              </li>
              <li>
                <a class="dropdown-item" href="#" @click="changeData1500()"
                  >1500</a
                >
              </li>
              <li>
                <a class="dropdown-item" href="#" @click="changeData2000()"
                  >2000</a
                >
              </li>
              <li>
                <a class="dropdown-item" href="#" @click="changeData50000()"
                  >50000</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="rangeContainer">
          <label for="customRange" class="form-label" id="time-label"
            >{{ this.selectedTime }}</label
          >
          <input
            type="range"
            class="form-range"
            id="customRange"
            @input="changeRangeValue"
          />
        </div>
        <div class="typePicker">
          <div class="dropdown">
            <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenu"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
             Színskála: {{ this.filterValue }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenu">
              <li v-if="this.isRSRQ">
                <a class="dropdown-item" href="#" @click="changeToRSRQ()"
                  >RSRQ</a
                >
              </li>
              <li v-if="this.isRSRP">
                <a class="dropdown-item" href="#" @click="changeToRSRP()"
                  >RSRP</a
                >
              </li>
              <li v-if="this.isDL">
                <a class="dropdown-item" href="#" @click="changeToDL()">DL</a>
              </li>
              <li v-if="this.isUL">
                <a class="dropdown-item" href="#" @click="changeToUL()">UL</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="mapView">
        <btn
          class="btn btn-primary"
          id="info-btn"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseInfo"
          aria-expanded="false"
          aria-controls="collapseInfo"
          @click="this.iconSwitch"
          ><i class="fa-solid fa-info" id="info-icon" v-if="this.infoVisible">
          </i
          ><i
            class="fa-solid fa-xmark"
            id="info-close-icon"
            v-if="this.xVisible"
          ></i
        ></btn>
        <div class="collapse" id="collapseInfo">
          <div class="card card-body" id="collapseContent">
            <table
              class="table"
              id="rsrqTable"
              v-if="this.filterValue == 'RSRQ'"
            >
              <thead>
                <tr>
                  <th colspan="2">RSRQ</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>>= -5</td>
                  <td style="background-color: #018100"></td>
                </tr>
                <tr>
                  <td>&lt; -5 és >= -10</td>
                  <td style="background-color: #01fe00"></td>
                </tr>
                <tr>
                  <td>&lt; -10 és >= -15</td>
                  <td style="background-color: #feff01"></td>
                </tr>
                <tr>
                  <td>&lt; -15 és >= -20</td>
                  <td style="background-color: #fe9901"></td>
                </tr>
                <tr>
                  <td>&lt; -20</td>
                  <td style="background-color: #ff0101"></td>
                </tr>
              </tbody>
            </table>
            <table
              class="table"
              id="rsrpTable"
              v-if="this.filterValue == 'RSRP'"
            >
              <thead>
                <tr>
                  <th colspan="2">RSRP</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>>= -60</td>
                  <td style="background-color: #0201f4"></td>
                </tr>
                <tr>
                  <td>&lt; -60 és >= -70</td>
                  <td style="background-color: #02fdf5"></td>
                </tr>
                <tr>
                  <td>&lt; -70 és >= -80</td>
                  <td style="background-color: #01fb02"></td>
                </tr>
                <tr>
                  <td>&lt; -80 és >= -90</td>
                  <td style="background-color: #cef5d4"></td>
                </tr>
                <tr>
                  <td>&lt; -90 és >= -100</td>
                  <td style="background-color: #fef801"></td>
                </tr>
                <tr>
                  <td>&lt; -100 és >= -110</td>
                  <td style="background-color: #f46501"></td>
                </tr>
                <tr>
                  <td>&lt; -110 és >= -120</td>
                  <td style="background-color: #f80004"></td>
                </tr>
                <tr>
                  <td>&lt; -120</td>
                  <td style="background-color: #010004"></td>
                </tr>
              </tbody>
            </table>
            <table
              class="table"
              id="rsrpTable"
              v-if="this.filterValue == 'UL' || this.filterValue == 'DL'"
            >
              <thead>
                <tr>
                  <th colspan="2">UL / DL</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>>= 8</td>
                  <td style="background-color: #0201f4"></td>
                </tr>
                <tr>
                  <td>>= 8 és &lt; 4</td>
                  <td style="background-color: #01fb02"></td>
                </tr>
                <tr>
                  <td>>= 4 és &lt; 2</td>
                  <td style="background-color: #fef801"></td>
                </tr>
                <tr>
                  <td>>= 2 és &lt; 1</td>
                  <td style="background-color: #f46501"></td>
                </tr>
                <tr>
                  <td>>= 1 és &lt; 0.1</td>
                  <td style="background-color: #f80004"></td>
                </tr>
                <tr>
                  <td>&lt; 0.1</td>
                  <td style="background-color: #010004"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="map" id="new-telco-map"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { BreedingRhombusSpinner } from "epic-spinners";

//import * as THREE from "three";

import dataProvider from "../utils/dataProvider.js";
const apiUrl =
  process.env.VUE_APP_API_URL || "https://testiot.ddc.sze.hu/external_api";
const dp = dataProvider(apiUrl);

import MapboxGl from "mapbox-gl"; // or "const mapboxgl = require('mapbox-gl');"


export default {
  components: {
    BreedingRhombusSpinner,
  },
  data() {
    return {
      myLineChart: undefined,

      mymap: undefined,

      zoom: 18,

      rawData: undefined,
      dataArray: [],

      altitudeOffset: undefined,

      jsonArray: [],
      smallerArray: [],
      resultArray: [],

      measureName: undefined,
      measureLocation: undefined,
      measureType: undefined,
      measureDevice: undefined,
      measureStartTime: undefined,
      measureEndTime: undefined,
      measureEngineer: undefined,

      filterValue: "RSRP",

      tableRSRP: undefined,
      tableRSRQ: undefined,
      tableRSSI: undefined,
      tableRTT: undefined,
      tableSNR: undefined,
      tableDL: undefined,
      tableUL: undefined,

      isBorder: false,

      allRSRP: [],
      allEARFCN: [],
      allPUSCH: [],
      allGPS: [],
      allPCI: [],
      allRSRQ: [],
      allRSSI: [],
      allRTT: [],
      allSNR: [],
      allDL: [],
      allUL: [],

      allTime: [],
      selectedTime: undefined,

      actualDL: undefined,
      actualUL: undefined,
      actualRSSI: undefined,
      actualRSRQ: undefined,
      actualRSRP: undefined,
      actualEARFCN: undefined,
      actualPUSCH: undefined,
      actualGPS: undefined,
      actualPCI: undefined,

      actualLat: undefined,
      actualLong: undefined,
      actualAlt: undefined,

      chartLabels: [],

      startTime: undefined,
      endTime: undefined,

      infoVisible: true,
      xVisible: false,

      allAccuracy: [],

      mostCommon: undefined,

      markerLats: [],
      markerLons: [],

      isRSRP: false,
      isRSRQ: false,
      isUL: false,
      isDL: false,
      isRSSI: false,
      isEARFCN: false,
      isPUSCH: false,
      isGPS: false,
      isPCI: false,

      dataCount: 2000,

      dataNum: undefined,
    };
  },
  name: "MainPage",
  props: {
    msg: String,
  },
  async mounted() {
    this.mainMethod();
  },
  methods: {
    async mainMethod() {
      document.getElementById("spinner").style.display = "block";
      document.getElementById("blur-div").style.display = "block";


      this.destroyCanvas();

      console.log(this.dataCount);

      this.rawData = await dp.getJurnalList(
        "devices",
        "35232ec0-f49f-46d8-81e5-dd04873864f4",
        this.dataCount,
        0,
        undefined,
        undefined
      );
      this.dataArray = this.rawData.data;

      //this.endTime = this.dataArray[this.dataArray.length - 1].timestamp;

      for (let i = 0; i < this.dataArray.length - 1; i++) {
        let obj = JSON.parse(JSON.parse(this.dataArray[i].records[0].data));
        this.jsonArray[i] = obj;
      }

      console.log(this.jsonArray);

      for(let i = 0; i < this.jsonArray.length-1; i++){
        if (!(this.jsonArray[i]["Lat"] == this.jsonArray[i+1]["Lat"] && this.jsonArray[i]["Long"] == this.jsonArray[i+1]["Long"])){
          this.smallerArray[i] = this.jsonArray[i];
        }
      }

      console.log(this.smallerArray);

      for(let i = 0; i < this.smallerArray.length; i++){
        if(this.smallerArray[i] != undefined){
          this.resultArray.push(this.smallerArray[i]);
        }
      }
      
      console.log(this.resultArray);

   
      

      for (let i = 0; i < this.resultArray.length; i++) {
        if (this.resultArray[i]["RSRP"]) {
          this.isRSRP = true;
        }
        if (this.resultArray[i]["RSRQ"]) {
          this.isRSRQ = true;
        }
        if (this.resultArray[i]["RSSI"]) {
          this.isRSSI = true;
        }
        if (this.resultArray[i]["Bandwidth UL"]) {
          this.isUL = true;
        }
        if (this.resultArray[i]["Bandwidth DL"]) {
          this.isDL = true;
        }
        if (this.resultArray[i]["LTE PCell EARFCN"]) {
          this.isEARFCN = true;
        }
        if (this.resultArray[i]["PUSCH Thrpt"]) {
          this.isPUSCH = true;
        }
        if (this.resultArray[i]["GPS status"]) {
          this.isGPS = true;
        }
        if (this.resultArray[i]["LTE PCell PCI"]) {
          this.isPCI = true;
        }

        this.allRSRP[i] = this.resultArray[i]["RSRP"];
        this.allRSRQ[i] = this.resultArray[i]["RSRQ"];
        this.allRSSI[i] = this.resultArray[i]["RSSI"];
        this.allDL[i] = this.resultArray[i]["Bandwidth DL"];
        this.allUL[i] = this.resultArray[i]["Bandwidth UL"];
        this.allTime[i] = this.resultArray[i]["Time"];
        this.allEARFCN[i] = this.resultArray[i]["LTE PCell EARFCN"];
        this.allPUSCH[i] = this.resultArray[i]["PUSCH Thrpt"];
        this.allGPS[i] = this.resultArray[i]["GPS status"];
        this.allPCI[i] = this.resultArray[i]["LTE PCell PCI"];
      }

      this.actualDL = this.resultArray[0]["Bandwidth DL"];
      this.actualRSRQ = this.resultArray[0]["RSRQ"];
      this.actualRSRP = this.resultArray[0]["RSRP"];
      this.actualRSSI = this.resultArray[0]["RSSI"];
      this.actualUL = this.resultArray[0]["Bandwidth UL"];
      this.actualEARFCN = this.resultArray[0]["LTE PCell EARFCN"];
      this.actualPUSCH = this.resultArray[0]["PUSCH Thrpt"];
      this.actualGPS = this.resultArray[0]["GPS status"];
      this.actualPCI = this.resultArray[0]["LTE PCell PCI"];


      this.actualLat = this.resultArray[0]["Lat"];
      this.actualLong = this.resultArray[0]["Long"];
      this.actualAlt = this.resultArray[0]["Altitude"];

      for (let i = 0; i < this.allTime.length; i++) {
        this.chartLabels[i] = JSON.stringify(this.allTime[i]).slice(12, 20);
      }
      this.chartLabels.reverse();

      for (let i = 0; i < this.resultArray.length; i++) {
        if (this.resultArray[i]["Long"] && this.resultArray[i]["Lat"]) {
          this.markerLons[i] = JSON.stringify(this.resultArray[i]["Long"]);
          this.markerLats[i] = JSON.stringify(this.resultArray[i]["Lat"]);
        }
      }

      this.measureEndTime = this.allTime[0].slice(11, -4);
      this.measureStartTime = this.allTime[this.allTime.length - 1].slice(
        11,
        -4
      );

      this.dataNum = this.dataArray.length;

      document.getElementById("customRange").max = this.resultArray.length - 1;
      document.getElementById("customRange").min = 0;

      let x = this.resultArray.length - 1;
      this.selectedTime = this.allTime[x];

      this.renderRadarChart();
      this.renderLineChart();

      this.map = new MapboxGl.Map({
        container: "new-telco-map",
        style: "mapbox://styles/mapbox/light-v11",
        accessToken:
          "pk.eyJ1IjoiY3NhYmluZW1ldGgiLCJhIjoiY2xlOGxibzV3MGFvdDN2cXNjbnM2Zm83dSJ9.1TJNwxyuw5o0pPxHZlfROQ",
        zoom: 18,
        center: [this.actualLong, this.actualLat],
        antialias: true,
        pitch: 45,
        bearing: -18,
      });

     

      let zoomControl = new MapboxGl.NavigationControl({
        showCompass: true,
        showZoom: false,
      });
      this.map.addControl(zoomControl, "bottom-left");

      // Add navigation control
      let navControl = new MapboxGl.FullscreenControl();
      this.map.addControl(navControl, "bottom-left");

      this.map.scrollZoom.disable();
      this.map.doubleClickZoom.disable();
      //this.map.touchZoomRotate.disable();

      this.map.on("style.load", () => {
        // Insert the layer beneath any symbol layer.
        const layers = this.map.getStyle().layers;
        const labelLayerId = layers.find(
          (layer) => layer.type === "symbol" && layer.layout["text-field"]
        ).id;

        // The 'building' layer in the Mapbox Streets
        // vector tileset contains building height data
        // from OpenStreetMap.
        this.map.addLayer(
          {
            id: "add-3d-buildings",
            source: "composite",
            "source-layer": "building",
            filter: ["==", "extrude", "true"],
            type: "fill-extrusion",
            minzoom: 15,
            paint: {
              "fill-extrusion-color": "#aaa",

              // Use an 'interpolate' expression to
              // add a smooth transition effect to
              // the buildings as the user zooms in.
              "fill-extrusion-height": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                15.05,
                ["get", "height"],
              ],
              "fill-extrusion-base": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                15.05,
                ["get", "min_height"],
              ],
              "fill-extrusion-opacity": 0.6,
            },
          },
          labelLayerId
        );
      });

      this.addMarkers();

      document.getElementById("blur-div").style.display = "none";
      document.getElementById("spinner").style.display = "none";
    },

    logout() {
      this.$router.push("/");
    },

    iconSwitch() {
      if (this.xVisible == false) {
        this.xVisible = true;
      } else {
        this.xVisible = false;
      }

      if (this.infoVisible == false) {
        this.infoVisible = true;
      } else {
        this.infoVisible = false;
      }
    },

    findMostCommon(array) {
      const countMap = {};
      let maxCount = 0;
      let mostCommonElement = null;

      // Count the occurrences of each element in the array
      for (const element of array) {
        if (!countMap[element]) {
          countMap[element] = 1;
        } else {
          countMap[element]++;
        }
      }

      // Find the most common element
      for (const element in countMap) {
        if (countMap[element] > maxCount) {
          maxCount = countMap[element];
          mostCommonElement = element;
        }
      }

      this.mostCommon = mostCommonElement;
    },

    addMarkers() {
      //const zoom = this.map.getZoom();
      const altitude = this.actualAlt; // Set the desired altitude in meters
      const altitudeOffset = altitude * 0.5; // Calculate a fixed offset based on referenceZoom

      for (let i = 0; i < this.markerLons.length; i++) {
        if (
          this.markerLats[i] != undefined &&
          this.markerLons[i] != undefined
        ) {
          let markerElement = document.createElement("div");
          markerElement.className = "marker";

          markerElement.style.position = "absolute";
          markerElement.style.top = `-${altitudeOffset}px`;
         // markerElement.style.zIndex = 1000;
          markerElement.style.cursor = "pointer";

          markerElement.addEventListener("click", () => {
            [];
            // Handle the click event here
            console.log("Marker" + i + "clicked!");

            if (this.isBorder == true) {
              var elements = document.getElementsByClassName("markerBorder");
              var elementsArray = Array.from(elements);
              elementsArray.forEach(function (element) {
                element.parentNode.removeChild(element);
              });
            }

            document.getElementById("customRange").value = i;

            this.selectedTime =
              this.allTime[document.getElementById("customRange").value];

            this.actualDL = this.resultArray[i]["Bandwidth DL"];
            this.actualRSRQ = this.resultArray[i]["RSRQ"];
            this.actualRSRP = this.resultArray[i]["RSRP"];
            this.actualRSSI = this.resultArray[i]["RSSI"];
            this.actualUL = this.resultArray[i]["Bandwidth UL"];

            this.actualLat = this.resultArray[i]["Lat"];
            this.actualLong = this.resultArray[i]["Long"];
            this.actualAlt = this.resultArray[i]["Altitude"];

            this.map.setCenter([this.actualLong, this.actualLat]);
            this.map.setCenter([this.actualLong, this.actualLat]);
          });

          new MapboxGl.Marker(markerElement)
            .setLngLat([this.markerLons[i], this.markerLats[i]])
            .addTo(this.map);

          let markerElement2 = document.createElement("div");
          markerElement2.className = "markerBorder";

          markerElement2.style.position = "absolute";
          markerElement2.style.top = `-${altitudeOffset}px`;
          markerElement2.style.zIndex = `90000`;

          new MapboxGl.Marker(markerElement2)
            .setLngLat([this.actualLong, this.actualLat])
            .addTo(this.map);

          this.isBorder = true;
        }
      }

      this.reColorMarkers();
    },

    destroyCanvas() {
      var canvasId = "measurements";
      var canvas = document.getElementById(canvasId);
      if (canvas) {
        var chart = Chart.getChart(canvas);

        if (chart) {
          chart.destroy();
        } else {
          console.log("No chart instance found with ID: " + canvasId);
        }
      } else {
        console.log("No canvas found with ID: " + canvasId);
      }

      var canvasId2 = "measurements2";
      var canvas2 = document.getElementById(canvasId2);
      if (canvas2) {
        var chart2 = Chart.getChart(canvas2);

        if (chart2) {
          chart2.destroy();
        } else {
          console.log("No chart instance found with ID: " + canvasId2);
        }
      } else {
        console.log("No canvas found with ID: " + canvasId2);
      }
    },

    reColorMarkers() {
      for (let i = 0; i < this.markerLons.length - 1; i++) {
        if (document.getElementsByClassName("marker")[i] != undefined) {
          if (this.filterValue == "RSRQ") {
            if (this.resultArray[i]["RSRQ"] <= -88) {
              document.getElementsByClassName("marker")[
                i
              ].style.backgroundColor = "#018100";
            } else if (-5 > this.resultArray[i]["RSRQ"] >= -10) {
              document.getElementsByClassName("marker")[
                i
              ].style.backgroundColor = "#01fe00";
            } else if (-10 > this.resultArray[i]["RSRQ"] >= -15) {
              document.getElementsByClassName("marker")[
                i
              ].style.backgroundColor = "#feff01";
            } else if (-15 > this.resultArray[i]["RSRQ"] >= -20) {
              document.getElementsByClassName("marker")[
                i
              ].style.backgroundColor = "#fe9901";
            } else if (-20 > this.resultArray[i]["RSRQ"]) {
              document.getElementsByClassName("marker")[
                i
              ].style.backgroundColor = "#ff0101";
            }
          }
          if (this.filterValue == "RSRP") {
            if (this.resultArray[i]["RSRP"] >= -60) {
              document.getElementsByClassName("marker")[
                i
              ].style.backgroundColor = "#0201f4";
            } else if (-60 > this.resultArray[i]["RSRP"] >= -70) {
              document.getElementsByClassName("marker")[
                i
              ].style.backgroundColor = "#02fdf5";
            } else if (-70 > this.resultArray[i]["RSRP"] >= -80) {
              document.getElementsByClassName("marker")[
                i
              ].style.backgroundColor = "#01fb02";
            } else if (-80 > this.resultArray[i]["RSRP"] >= -90) {
              document.getElementsByClassName("marker")[
                i
              ].style.backgroundColor = "#cef5d4";
            } else if (-90 > this.resultArray[i]["RSRP"] >= -100) {
              document.getElementsByClassName("marker")[
                i
              ].style.backgroundColor = "#fef801";
            } else if (-100 > this.resultArray[i]["RSRP"] >= -110) {
              document.getElementsByClassName("marker")[
                i
              ].style.backgroundColor = "#f46501";
            } else if (-110 > this.resultArray[i]["RSRP"] >= -120) {
              document.getElementsByClassName("marker")[
                i
              ].style.backgroundColor = "#f80004";
            } else if (-120 > this.resultArray[i]["RSRP"]) {
              document.getElementsByClassName("marker")[
                i
              ].style.backgroundColor = "#010004";
            }
          }
          if (this.filterValue == "RSSI") {
            if (this.resultArray[i]["RSSI"] <= -70) {
              document.getElementsByClassName("marker")[
                i
              ].style.backgroundColor = "red";
            } else {
              document.getElementsByClassName("marker")[
                i
              ].style.backgroundColor = "green";
            }
          }
          if (this.filterValue == "UL") {
            if (this.resultArray[i]["UL"] <= -70) {
              document.getElementsByClassName("marker")[
                i
              ].style.backgroundColor = "red";
            } else {
              document.getElementsByClassName("marker")[
                i
              ].style.backgroundColor = "green";
            }
          }
          if (this.filterValue == "DL") {
            if (this.resultArray[i]["DL"] <= -70) {
              document.getElementsByClassName("marker")[
                i
              ].style.backgroundColor = "red";
            } else {
              document.getElementsByClassName("marker")[
                i
              ].style.backgroundColor = "green";
            }
          }
        }
      }
    },

    changeData100() {
      this.dataCount = 100;
      this.mainMethod();
    },
    changeData500() {
      this.dataCount = 500;
      this.mainMethod();
    },
    changeData1000() {
      this.dataCount = 1000;
      this.mainMethod();
    },
    changeData1500() {
      this.dataCount = 1500;
      this.mainMethod();
    },
    changeData2000() {
      this.dataCount = 2000;
      this.mainMethod();
    },
    changeData50000() {
      this.dataCount = 50000;
      this.mainMethod();
    },

    changeToRSRQ() {
      this.filterValue = "RSRQ";
      this.reColorMarkers();
    },
    changeToRSRP() {
      this.filterValue = "RSRP";
      this.reColorMarkers();
    },
    changeToRSSI() {
      this.filterValue = "RSSI";
      this.reColorMarkers();
    },
    changeToUL() {
      this.filterValue = "UL";
      this.reColorMarkers();
    },
    changeToDL() {
      this.filterValue = "DL";
      this.reColorMarkers();
    },

    toUsers() {
      this.$router.push("../userList");
    },
    toMeasures() {
      this.$router.push("../listView");
    },
    toLogs() {
      this.$router.push("../logList");
    },
    toStations() {
      this.$router.push("../stationList");
    },

    renderRadarChart() {
      new Chart(document.getElementById("measurements"), {
        type: "radar",
        data: {
          labels: ["DL", "UL", "RSSI", "RSRQ", "RSRP", "PCI", "PUSCH Thrpt"],
          datasets: [
            {
              label: "Measurements",
              data: [
                this.actualDL,
                this.actualUL,
                this.actualRSSI,
                this.actualRSRQ,
                this.actualRSRP,
                this.actualPCI,
                this.actualPUSCH
              ],
              dataLabels: [],
              fill: true,

              borderColor: "#06FF00",
              pointBackgroundColor: "rgb(255, 99, 132)",
              pointBorderColor: "#fff",
              pointLabel: [],
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgb(255, 99, 132)",
            },
          ],
        },

        options: {
          scale: {
            pointLabels: {
              fonSize: 0,
            },
          },
        },
      });
    },

    renderLineChart() {
      new Chart(document.getElementById("measurements2"), {
        type: "line",
        data: {
          labels: this.chartLabels,
          datasets: [
            {
              label: "RSRP",
              backgroundColor: "#f87979",
              borderColor: "#f87979",
              data: this.allRSRP,
            },
            {
              label: "RSRQ",
              backgroundColor: "#7fc97f",
              borderColor: "#7fc97f",
              data: this.allRSRQ,
            },
            {
              label: "RSSI",
              backgroundColor: "#ea5545",
              borderColor: "#ea5545",
              data: this.allRSSI,
            },
            {
              label: "DL",
              backgroundColor: "#9b19f5",
              borderColor: "#9b19f5",
              data: this.allDL,
            },
            {
              label: "UL",
              backgroundColor: "#27aeef",
              borderColor: "#27aeef",
              data: this.allUL,
            },
            {
              label: "PCI",
              backgroundColor: "#5C8984",
              borderColor: "#5C8984",
              data: this.allPCI,
            },
            {
              label: "EARFCN",
              backgroundColor: "#374259",
              borderColor: "#374259",
              data: this.allEARFCN,
              hidden: true,
            },
            {
              label: "PUSCH Thrpt",
              backgroundColor: "#FFD966",
              borderColor: "#FFD966",
              data: this.allPUSCH,
            },
          ],
        },

        options: {},
      });
    },

    exportToCSV() {
      let csvContent = "data:text/csv;charset=utf-8,";
      const headers = Object.keys(this.jsonArray[0]).join(","); // Assuming all objects in the array have the same structure
      csvContent += headers + "\n";

      this.jsonArray.forEach((item) => {
        const row = Object.values(item).join(",");
        csvContent += row + "\n";
      });

      // Create a temporary <a> element to initiate the download
      const link = document.createElement("a");
      link.href = encodeURI(csvContent);
      link.download = "data.csv";
      link.click();
    },

    changeRangeValue() {
      if (this.isBorder == true) {
        var elements = document.getElementsByClassName("markerBorder");
        var elementsArray = Array.from(elements);
        elementsArray.forEach(function (element) {
          element.parentNode.removeChild(element);
        });
      }

      this.selectedTime =
        this.allTime[document.getElementById("customRange").value];

      for (let i = 0; i <= this.resultArray.length; i++) {
        if (this.resultArray[i]["Time"] == this.selectedTime) {
          this.actualDL = this.resultArray[i]["Bandwidth DL"];
          this.actualRSRQ = this.resultArray[i]["RSRQ"];
          this.actualRSRP = this.resultArray[i]["RSRP"];
          this.actualRSSI = this.resultArray[i]["RSSI"];
          this.actualUL = this.resultArray[i]["Bandwidth UL"];

          this.actualLat = this.resultArray[i]["Lat"];
          this.actualLong = this.resultArray[i]["Long"];
          this.actualAlt = this.resultArray[i]["Altitude"];

          this.map.setCenter([this.actualLong, this.actualLat]);
          this.map.setCenter([this.actualLong, this.actualLat]);

          let radar = Chart.getChart("measurements");
          if (radar) {
            radar.destroy();
          }
          this.renderRadarChart();

          break;
        }
      }

      //const zoom = this.map.getZoom();
      const altitude = this.actualAlt; // Set the desired altitude in meters
      const altitudeOffset = altitude * 0.5; // Calculate a fixed offset based on referenceZoom

      let markerElement = document.createElement("div");
      markerElement.className = "markerBorder";

      markerElement.style.position = "absolute";
      markerElement.style.top = `-${altitudeOffset}px`;

      new MapboxGl.Marker(markerElement)
        .setLngLat([this.actualLong, this.actualLat])
        .addTo(this.map);

      this.isBorder = true;
    },

    toLineChart() {
      document.getElementById("measurements").style.display = "none";
      document.getElementById("measurements2").style.display = "block";

      document.getElementById("measurements").style.opacity = "0";
      document.getElementById("measurements2").style.opacity = "1";

      document.getElementById("customRange").disabled = true;
    },
    toRadarChart() {
      document.getElementById("measurements2").style.display = "none";
      document.getElementById("measurements").style.display = "block";

      document.getElementById("measurements").style.opacity = "1";
      document.getElementById("measurements2").style.opacity = "0";
      document.getElementById("customRange").disabled = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import "mapbox-gl/dist/mapbox-gl.css";

.marker {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
}

.markerBorder {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid white;
  background: transparent;
}

.sidebar {
  position: fixed;
  left: 0;
  height: 100vh;
  top: 0;
  width: 4vw;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 10px 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#new-telco-map {
  height: 95%;
  margin: 10px;
  position: relative;
  top: 10px;
  left: 0rem;
  width: 88rem;
  border-radius: 5px;
}

.main-content {
  position: relative;
  width: 95vw;
  text-align: center;
  overflow-x: hidden;
  display: block;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  left: 3vw;
}

.content {
  background: linear-gradient(
    180deg,
    #1d2138 2.92%,
    #222954 46.15%,
    #222954 67.5%
  );
  width: 100vw;
  height: auto;
  position: absolute;
  min-height: 100vh;
  top: 0;
  left: 0;
  max-width: 100vw;
  text-align: center;
  overflow-x: hidden;
  align-items: center;
  justify-content: center;
}

.row1 {
  display: flex;
  max-width: 89.5rem;
  min-width: 89.5rem;
  position: relative;
  max-height: 28vw;
  margin-bottom: 1rem;
  margin-top: 2rem;
  left: 1rem;
  overflow: hidden;
}

.row2 {
  display: flex;
  position: relative;
  max-width: 89.5rem;
  min-width: 89.5rem;
  position: relative;
  top: 0;
  left: 1rem;
  max-height: 3rem;
  margin-bottom: 10px;
}

.typePicker {
  width: 10rem;
  position: relative;
  max-height: 2vh;
}

.dataPicker {
  width: 10rem;
  position: relative;
  min-height: 8rem;
  margin-right: 4rem;
}

.main-data-title {
  position: relative;
  width: 89.5rem;
  height: 5rem;
  background-color: black;
  border-radius: 5px;
  top: 1rem;
  left: 1rem;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

body {
  overflow-x: hidden !important;
}

#export-button {
  position: relative;
  left: 10px;
}

#title-table {
  color: white;
  width: 88.5rem;
  position: relative;
  left: 0.5rem;
  height: 4rem;
  font-size: 15px;
  top: 0.5rem;
}

#data-table {
  color: white;
  width: 97%;
  background-color: transparent;
  margin: 10px;
  height: 300px;
}

.main-table {
  position: relative;
  min-width: 40rem;
  min-height: 15rem;
  background-color: black;
  border-radius: 5px;
  left: 0vw;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-right: 1rem;
  height: 23rem;
}

.main-charts {
  position: relative;
  min-width: 50rem;
  height: 23rem;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
}


#measurements {
  max-width: 40rem;
  position: relative;
  display: none;
  opacity: 1;
  left: 5rem;
  max-height: 95%;
}

#measurements2 {
  max-width: 40rem;
  position: relative;
  left: 1rem;
  max-height: 95%;
  opacity: 0;
}

#blur-div {
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.5);
  width: 120%;
  height: 100%;
  left: 4vw;
  top: 0;
  z-index: 9999999;
  position: absolute;
  opacity: 1;
}

#dropdownMenu {
  min-height: 3rem;
  max-height: 3rem;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 0;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;
  z-index: 99;
  left: 1.5rem;
  width: 14rem;
}

#dropdownData {
  min-height: 3rem;
  max-height: 3rem;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 0;
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
  z-index: 1000;
  left: 0;
}

.dropdown-menu {
  min-height: 8.5vh;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 0;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;
  z-index: 99;
  color: white;
}

.dropdown-item {
  color: white;
}

.dropdown-item:hover {
  background: #222954;
  color: white;
}

.rangeContainer {
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: white;
  z-index: 99;
  width: 59rem;
  min-height: 3rem;
  max-height: 3rem;

  position: relative;
  left: 1em;
  margin-bottom: 20px;
  border-radius: 5px;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

#time-label{
  top: -10px;
  position: relative;
}

#customrange{
  margin-top: 10px;
}

.form-range::-webkit-slider-runnable-track {
  background-color: #222954;
}





#chart-btn {
  min-height: 80px;
  top: 20%;
  margin-top: 10px;
  position: relative;
  left: 10px;
}

.mapView {
  position: relative;
  width: 89.5rem;
  background-color: black;
  border-radius: 5px;
  left: 1vw;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 80vh;
  max-width: 100vw;
}

#iconActive {
  width: auto;
  height: auto;
  font-size: 20px;
  background-color: transparent;
  border: 0;
  margin-bottom: 10px;
}

#icon {
  width: auto;
  height: auto;
  font-size: 20px;
  background-color: transparent;
  border: 0;
  opacity: 50%;
  margin-bottom: 10px;
}

#icon-logout {
  width: auto;
  height: auto;
  font-size: 20px;
  background-color: transparent;
  border: 0;
  opacity: 50%;
  margin-bottom: 10px;
}

#icon-logout:hover {
  opacity: 100%;
}

#icon:hover {
  opacity: 100%;
}

body::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
}

body::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

#spinner {
  position: absolute;
  left: 48%;
  top: 25%;
  z-index: 10000000;
}

#info-btn {
  position: absolute;
  z-index: 100000;
  right: 25px;
  border-radius: 50%;
  border: 0;
  top: 25px;
  width: 50px;
  height: 50px;
  text-align: center;
  background-color: #222954;
}

#info-icon {
  top: 15%;
  position: relative;
  vertical-align: center;
}

#info-close-icon {
  top: 20%;
  position: relative;
  vertical-align: center;
}

#collapseInfo {
  position: absolute;
  z-index: 90000;
  right: 0;
  min-height: 200px;
}

#collapseContent {
  z-index: 90000;
  min-height: 120px;
  width: 350px;
  padding-right: 100px;
  background-color: #f8f9f9;
}
</style>
