<template>
  <div class="content">
    <div class="sidebar">
      <button class="btn btn-primary" id="icon" data-bs-toggle="tooltip" data-bs-placement="right" title="Mérések" @click="toListView()">
        <i class="fa-solid fa-chart-column"></i>
      </button>
      <button class="btn btn-primary" id="icon" data-bs-toggle="tooltip" data-bs-placement="right" title="Profil">
        <i class="fa-solid fa-user"></i>
      </button>
      <button class="btn btn-primary" id="iconActive" data-bs-toggle="tooltip" data-bs-placement="right" title="Felhasználók" @click="toUsers()">
        <i class="fa-solid fa-users"></i>
      </button>
      <button class="btn btn-primary" id="icon" data-bs-toggle="tooltip" data-bs-placement="right" title="Bejegyzések" @click="toLogs()">
        <i class="fa-solid fa-list"></i>
      </button>
      <button class="btn btn-primary" id="icon" data-bs-toggle="tooltip" data-bs-placement="right" title="Bázisállomások" @click="toStations()">
        <i class="fa-solid fa-tower-cell"></i>
      </button>
      <button class="btn btn-primary" id="icon" data-bs-toggle="tooltip" data-bs-placement="right" title="Kijelentkezés" @click="this.logout()">
        <i class="fa-solid fa-arrow-right-from-bracket"></i>
      </button>
    </div>

    <div class="data">
      <h2 class="table-title">Felhasználók</h2>
      <table class="table" id="measure-table">
        <thead>
          <tr>
            <th scope="col">Felhasználónév</th>
            <th scope="col">E-mail</th>
            <th scope="col">Telefon</th>
            <th scope="col">Szervezet</th>
            <th scope="col"><i class="fa-solid fa-magnifying-glass"></i></th>
          </tr>
        </thead>
        <tbody>
          <tr id="measure-tr" @click="toMeasure()">
            <th>Ktommy25</th>
            <td>kovacs.tamas@ddc.sze.hu</td>
            <td>+36205837746</td>
            <td>DDC</td>
            <td><i class="fa-solid fa-pen-to-square" id="edit-icon"></i>&emsp;<i class="fa-solid fa-trash-can" id="trash-icon"></i></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>


export default {
  name: "UserList",
  props: {
    msg: String,
  },

  methods: {
    toListView(){
      this.$router.push("../listView");
    },
    toStations(){
      this.$router.push("../stationList");
    },
    toLogs(){
      this.$router.push("../logList");
    },
    logout() {
      this.$router.push("/");
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  background: linear-gradient(
    180deg,
    #1d2138 2.92%,
    #222954 46.15%,
    #222954 67.5%
  );
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.data {
  width: 96vw;
  height: 100vh;
  position: absolute;
  overflow: hidden;
}

.table-title{
  color: white;
  left: 7vw;
  top: 2vh;
  position: relative;
}

.sidebar {
  position: absolute;
  left: 0;
  height: 100vh;
  top: 0;
  width: 4vw;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 20px 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

#measure-table{
  width: 90vw;
  position: absolute;
  left: 7vw;
  background-color: #1d2138;
  color: white;
  top: 12vh;
  font-size: 15px;
}

#measure-tr{
  cursor: pointer;
}

#iconActive {
  width: auto;
  height: auto;
  font-size: 20px;
  background-color: transparent;
  border: 0;
  margin-bottom: 10px;
}

#icon {
  width: auto;
  height: auto;
  font-size: 20px;
  background-color: transparent;
  border: 0;
  opacity: 50%;
  margin-bottom: 10px;
}

#icon:hover {
  opacity: 100%;
}

#edit-icon{
  color: greenyellow;
}

#trash-icon{
  color: red;
}
</style>
