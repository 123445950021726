import axios from "axios";


const dataProvider = (apiUrl) => {
    const recreateAxios = () => {
        const auth = JSON.parse(localStorage.getItem("auth"));
        let token;
        let headers = {};
        if (auth && auth.accessToken) {
          token = auth.accessToken;
          headers = {
            Authorization: "Bearer " + token,
            Accept: "*/*",
          };
        }
    
        const ax = axios.create({
          baseURL: apiUrl,
          headers: headers,
        });
        return ax;
      };
  return {
    getJurnalList: (
      // ezt kell majd átcsinálni journalosra
      resource,
      deviceuuid,
      pageSize,
      pagereq,
      fromDate,
      toDate
    ) => {
      let route = "";
      switch (resource) {
        case "devices":
          route = "device/journal/events/list";
          break;
        default:
          route = "";
      }

      console.log(resource,
        deviceuuid,
        pageSize,
        pagereq,
        fromDate,
        toDate)

      let url = `${apiUrl}/${route}`;
      let data = {
        descending: true,
        pageInfo: {
          pageSize: pageSize,
          pageNum: pagereq,
        },
      };
      const ax = recreateAxios();
      return ax({
        method: "POST",
        url: url,
        data: data,
        params: {
          deviceuuid: deviceuuid,
          from: fromDate,
          to: toDate,
        },
      })
        .then((res) => {
          return Promise.resolve({ data: res.data.data });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  };
};
export default dataProvider;
