import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { DateTime } from 'luxon';
//const apiUrl = process.env.VUE_APP_API_URL || "https://iot.ddc.sze.hu/external_api";

const authProvider = (apiUrl) => {
  const ax = axios.create({
    baseURL: apiUrl
  }) 
  return {
    
    login: ( username, password ) => {
      //console.log("login called")
      return new Promise((resolve, reject) => {
        ax({
          method: 'POST',
          url:'/user/authorize',
          data: {
            username: username,
            password: password
          }
        }).then(res =>{
          let strippedAccessToken = res.data.accessToken
          let strippedRefreshToken = res.data.refreshToken

          const decodedAccessToken = jwt_decode(strippedAccessToken)
          const decodedRefreshToken = jwt_decode(strippedRefreshToken)

          localStorage.setItem('auth', JSON.stringify({
            accessToken: strippedAccessToken,
            accessTokenExp: Math.floor(Number(decodedAccessToken.exp)/1e6),
            refreshToken: strippedRefreshToken,
            refreshTokenExp: Math.floor(Number(decodedRefreshToken.exp)/1e6)
          }))
          resolve()
        }).catch(err => {
          console.dir(err)
          if(err && err.response && err.response.status &&  err.response.status === 401){
            reject('invalid username or password')
          } else {
            reject(err)
          }
        
        })
      })
    },
    checkError: error => {
      //console.log("checkerror called")
      return new Promise((resolve, reject)=>{
        if(error && error.message === "ra.notification.data_provider_error"){
          resolve()
        } else 
        reject()
      })
    },
    checkAuth: () => {
      //console.log("checkauth called")
      return new Promise((resolve, reject) => {
        let auth = localStorage.getItem('auth')
        auth = JSON.parse(auth)
        if(auth && DateTime.utc() < DateTime.fromMillis(auth.accessTokenExp)){
          resolve()
        } else {
          if(auth && auth.refreshToken && DateTime.utc() < DateTime.fromMillis(auth.refreshTokenExp)){
            // FIXME: implement this when backend is ready for it
            reject()
            /*ax({
              method: 'POST',
              url: '/token/extend',
              params: {
                refreshToken: auth.refreshToken
              }
            }).then(res => {
              FIXME IMPLEMENT THIS
            }).catch(err => {
              reject(err)
            })*/
          } else {
            reject()
          }
        }
      })
    },
    logout: () => {
      localStorage.removeItem('auth')
      return Promise.resolve()
    },
    getIdentity: () => {
      //console.log("getIdentity called")
      return Promise.resolve()
    },
    // authorization
    getPermissions: () => {
      //console.log("getPermissions called")
      return Promise.resolve()
    },
  }
};

export default authProvider;