<template>
  <div class="login-container">
    <div class="pics1">
      <div class="pic11"></div>
      <div class="pic12"></div>
      <div class="pic13"></div>
      <div class="pic14"></div>
    </div>
    <div class="pics2">
      <div class="pic21"></div>
      <div class="pic22"></div>
      <div class="pic23"></div>
    </div>
    <div class="pics3">
      <div class="pic31"></div>
      <div class="pic32"></div>
      <div class="pic33"></div>
      <div class="pic34"></div>
    </div>
    <div class="pics4">
      <div class="pic41"></div>
      <div class="pic42"></div>
      <div class="pic43"></div>
    </div>
    <div class="pics5">
      <div class="pic51"></div>
      <div class="pic52"></div>
      <div class="pic53"></div>
      <div class="pic54"></div>
    </div>

    <div class="login" id="login-div">
      <h2 class="login-title" id="login-head">Bejelentkezés</h2>
      <div class="input-group" id="login-form">
        <label class="form-label" id="errorMessage"
          >Hibás felhasználónév vagy jelszó!</label
        >
        <br />
        <label class="form-label">Felhasználónév:</label>
        <input
          type="text"
          class="form-control input"
          id="username-input"
          aria-label="Username"
          aria-describedby="basic-addon1"
          @keydown.enter="login()"
        />
        <br />
        <label class="form-label">Jelszó:</label>
        <div class="row-pass">
          <input
            type="password"
            class="form-control input"
            id="password-input"
            aria-describedby="basic-addon1"
            @keydown.enter="login()"
          /><i
            class="fa-regular fa-eye"
            id="eye-icon"
            @click="this.showPass()"
          ></i>
          <i
            class="fa-regular fa-eye-slash"
            id="eye-slash-icon"
            @click="this.hidePass()"
          ></i>
        </div>
        <br />
        <button
          class="btn btn-primary py-0 px-0"
          type="submit"
          id="loginBtn"
          @keydown.enter="login()"
          @click="login()"
        >
          Bejelentkezés
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import App from "../App.vue";
import ddcAuthProvider from "../utils/authProvider.js";
const apiUrl =
  process.env.VUE_APP_API_URL || "https://testiot.ddc.sze.hu/external_api";
const authProvider = ddcAuthProvider(apiUrl);

export default {
  name: "LoginPage",
  props: {
    msg: String,
  },
  data() {
    return {
      username: null,
      password: null,
      error: null,
      deviceCount: 0,
      domainCount: 0,
      userCount: 0,
    };
  },
  methods: {
    toLisView() {
      this.$router.push("../listView");
    },

    isEnter() {
      if (event.keyCode == 13) {
        document.getElementById("loginBtn").click();
      }
    },

    showPass() {
      document.getElementById("password-input").type = "text";
      document.getElementById("eye-slash-icon").style.display = "block";
      document.getElementById("eye-icon").style.display = "none";
    },

    hidePass() {
      document.getElementById("password-input").type = "password";
      document.getElementById("eye-slash-icon").style.display = "none";
      document.getElementById("eye-icon").style.display = "block";
    },

    moveBox() {
      var box = document.getElementById('login-div');
      box.style.left = '52%'; // Move right
      box.addEventListener('transitionend', function() {
        box.style.left = '48%'; // Move left
        box.addEventListener('transitionend', function() {
          box.style.left = '50%'; // Return to original position
        });
      });
    },

    login() {
      this.username = document.getElementById("username-input").value;
      this.password = document.getElementById("password-input").value;
      //console.log(this.username, this.password);
      localStorage.setItem("theme", "");

      authProvider
        .login(this.username, this.password)
        .then(() => {
          App.loggedIn = true;
          localStorage.setItem("username", this.username);
          App.user = localStorage.getItem("username");

          //document.getElementById("login-div").style.opacity = 0;
          document.getElementById("login-head").style.opacity = 0;
          document.getElementById("login-form").style.opacity = 0;
          document.getElementById("login-div").style.width = 0;

          setTimeout(() => {
            this.$router.push("/listView");
          }, "1000");

          //console.log(App.loggedIn);
        })
        .catch((err) => {
          if (err == "Error: Request failed with status code 500") {
            this.error = "Invalid Username or Password. Please try again!";
          } else {
            this.error = err + ". Please try again!";
            document.getElementById("errorMessage").style.opacity = 1;
            document.getElementById("username-input").style.border =
              "1px solid red";
            document.getElementById("password-input").style.border =
              "1px solid red";
            this.moveBox();
          }
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login-container {
  background: linear-gradient(
    180deg,
    #1d2138 2.92%,
    #222954 46.15%,
    #222954 67.5%
  );
  width: 100vw;
  height: 100vh;
  position: absolute;
  overflow: hidden;
  z-index: 100;
}

.login {
  background: rgba(255, 255, 255, 0.7);
  width: 40vw;
  height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  backdrop-filter: blur(5px);
  transition: height 1s, width 1s, left 0.1s;
}

.row-pass {
  display: flex;
}

#eye-icon {
  display: block;
  position: relative;
  top: 8px;
  margin-left: 5px;
  cursor: pointer;
  z-index: 9999;
}

#eye-slash-icon {
  display: none;
  position: relative;
  top: 8px;
  margin-left: 5px;
  z-index: 9999;
  cursor: pointer;
}

#errorMessage {
  color: red;
  opacity: 0;
}

.login-title {
  color: black;
  font-family: "myFirstFont";
  font-size: 8vh;
  font-weight: 400;
  position: relative;
  top: 20%;
  left: 66%;
  width: 100%;
  transform: translate(-50%, -50%);
  transition: opacity 0.1s;
}

.input-group {
  height: 50vh;
  width: 40vw;
  position: relative;
  margin: 10px;
  display: block;
  top: 20%;
  left: 30%;
  transition: opacity 0.1s;
}

#loginBtn {
  height: 30px;
  width: 200px;
  position: relative;
  top: 20%;
  background: #477bff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  left: 0%;
  transition: width 0.2s, height 0.2s, left 0.2s;
}

#loginBtn:hover {
  height: 35px;
  width: 210px;
  left: -0.5%;
}

#username-input {
  height: 30px;
  width: 200px;
  border-radius: 20px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 0;
}

#password-input {
  height: 30px;
  width: 200px;
  border-radius: 20px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 0;
}

.pics1 {
  position: absolute;
  width: 20vw;
  left: 0;
  height: 100vh;
  top: 0;
  filter: grayscale(100%);
  animation: up-down 10s linear infinite; /* Apply the animation */
}

@keyframes up-down {
  0% {
    transform: translateY(0); /* Start at the original position */
  }
  50% {
    transform: translateY(-30px); /* Move up by 10 pixels */
  }
  100% {
    transform: translateY(0); /* End at the original position */
  }
}

.pic11 {
  background-image: url(../../public/smaller/torony2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: 0;
  height: 30vh;
  width: 20vw;
}

.pic12 {
  background-image: url(../../public/smaller/torony1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: 0;
  height: 30vh;
  width: 20vw;
}

.pic13 {
  background-image: url(../../public/smaller/torony3.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: 0;
  height: 30vh;
  width: 20vw;
}

.pic14 {
  background-image: url(../../public/smaller/torony4.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: 0;
  height: 30vh;
  width: 20vw;
}

.pics2 {
  position: absolute;
  width: 20vw;
  left: 20vw;
  height: 100vh;
  top: 0;
  filter: grayscale(100%);
  animation: move-down-up 10s ease-in-out infinite; /* Apply the animation */
}

.pics4 {
  position: absolute;
  width: 20vw;
  left: 60vw;
  height: 100vh;
  top: 0;
  filter: grayscale(100%);
  animation: move-down-up 10s ease-in-out infinite; /* Apply the animation */
}

.pic41 {
  background-image: url(../../public/smaller/torony15.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: -30px;
  height: 40vh;
  width: 20vw;
}

.pic42 {
  background-image: url(../../public/smaller/torony14.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: -30px;
  height: 40vh;
  width: 20vw;
}

.pic43 {
  background-image: url(../../public/smaller/torony13.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: -30px;
  height: 40vh;
  width: 20vw;
}

.pic51 {
  background-image: url(../../public/smaller/torony17.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: 0;
  height: 30vh;
  width: 20vw;
}

.pic52 {
  background-image: url(../../public/smaller/torony16.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: 0;
  height: 30vh;
  width: 20vw;
}

.pic53 {
  background-image: url(../../public/smaller/torony18.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: 0;
  height: 30vh;
  width: 20vw;
}

.pic54 {
  background-image: url(../../public/smaller/torony19.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: 0;
  height: 30vh;
  width: 20vw;
}

.pics5 {
  position: absolute;
  width: 20vw;
  left: 80vw;
  height: 100vh;
  top: 0;
  filter: grayscale(100%);
  animation: up-down 10s linear infinite; /* Apply the animation */
}

@keyframes move-down-up {
  0% {
    transform: translateY(0); /* Start at the top */
  }
  50% {
    transform: translateY(30px); /* Move down to the bottom */
  }
  100% {
    transform: translateY(0); /* Move back up to the top */
  }
}

.pic21 {
  background-image: url(../../public/smaller/torony5.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: -30px;
  height: 40vh;
  width: 20vw;
}

.pic22 {
  background-image: url(../../public/smaller/torony6.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: -30px;
  height: 40vh;
  width: 20vw;
}

.pic23 {
  background-image: url(../../public/smaller/torony7.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: -30px;
  height: 40vh;
  width: 20vw;
}

.pics3 {
  position: absolute;
  width: 20vw;
  left: 40vw;
  height: 100vh;
  top: 0;
  filter: grayscale(100%);
  animation: up-down 10s linear infinite; /* Apply the animation */
}

.pic31 {
  background-image: url(../../public/smaller/torony9.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: 0;
  height: 30vh;
  width: 20vw;
}

.pic32 {
  background-image: url(../../public/smaller/torony10.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: 0;
  height: 30vh;
  width: 20vw;
}

.pic33 {
  background-image: url(../../public/smaller/torony11.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: 0;
  height: 30vh;
  width: 20vw;
}

.pic34 {
  background-image: url(../../public/smaller/torony12.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: 0;
  height: 30vh;
  width: 20vw;
}

@font-face {
  font-family: myFirstFont;
  src: url(../../public/Montserrat-Regular.ttf);
}
</style>
