import { createRouter, createWebHistory } from "vue-router";
import ListView from "../components/ListView.vue";
import LoginPage from "../components/LoginPage.vue";
import MainPage from "../components/MainPage.vue";
import StationList from "../components/StationList.vue";
import StationPage from "../components/StationPage.vue";
import UserList from "../components/UserList.vue";
import LogList from "../components/LogList.vue";



const routes = [
    {
        path: "/",
        name: LoginPage,
        component: LoginPage,
    },
    {
        path: "/listView",
        name: ListView,
        component: ListView,
    },
    {
        path: "/mainPage",
        name: MainPage,
        component: MainPage,
    },
    {
        path: "/stationList",
        name: StationList,
        component: StationList,
    },
    {
        path: "/stationPage",
        name: StationPage,
        component: StationPage,
    },
    {
        path: "/userList",
        name: UserList,
        component: UserList,
    },
    {
        path: "/logList",
        name: LogList,
        component: LogList,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});


export default router;